<template>
    <div class="problemFound">
        <header>
            <div class="tabs">
                <el-tabs v-model="mainTab" @tab-click="mainTabClick" class="main-tab">
                    <el-tab-pane v-if="subMenu.task_execution_config" label="任务执行配置" name="0"></el-tab-pane>
                    <el-tab-pane
                        v-if="subMenu.collaborative_office_configuration"
                        label="协同办公配置"
                        name="1"
                    ></el-tab-pane>
                </el-tabs>
            </div>
        </header>
        <main>
            <div class="tab-bar">
                <div class="subToolbar">
                    <div class="title" v-if="mainTab == '0' && subMenu.task_execution_config">任务执行配置列表</div>
                    <div class="title" v-if="mainTab == '1' && subMenu.collaborative_office_configuration">
                        协同办公配置列表
                    </div>
                </div>
            </div>
            <div style="width: 100%" v-if="mainTab == '1'">
                <div style="width: 100%; display: flex; justify-content: space-between">
                    <el-form :inline="true" :model="query">
                        <el-form-item label="用户范围">
                            <el-select v-model="barsId" class="search-input" multiple placeholder="请选择">
                                <el-option
                                    v-for="(item, index) in option_bar"
                                    :key="item.id"
                                    :label="item.barsName"
                                    :value="item.id + ''"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="应用类型" prop="applyType">
                            <el-select
                                v-model="query.applyType"
                                class="search-input"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="(item, index) in option_type"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="btns">
                        <el-button v-if="userBtns.cockpit_collaborativeoffice_add" type="primary" @click="hangleAdd"
                            >新 增</el-button
                        >
                        <el-button @click="search" type="primary">查 询</el-button>
                        <el-button @click="resize" plain>重 置</el-button>
                    </div>
                </div>
            </div>
            <div style="width: 100%" v-if="mainTab == '0'">
                <div style="width: 100%; display: flex; justify-content: space-between">
                    <el-form :inline="true" :model="query"> </el-form>
                    <div class="btns">
                        <el-button
                            v-if="userBtns.cockpit_collaborativeoffice_add"
                            type="primary"
                            @click="addItem(tableData[0], '新增任务执行配置')"
                            >新 增</el-button
                        >
                    </div>
                </div>
            </div>
            <div class="table" v-if="mainTab == '0' && subMenu.task_execution_config">
                <el-table
                    key="1"
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                >
                    <el-table-column prop="menuName" label="菜单名称" />
                    <el-table-column prop="orgDivisionName" label="所属行政区域" show-overflow-tooltip />
                    <el-table-column prop="actionYear" label="年份" show-overflow-tooltip />

                    <el-table-column prop="redirectUrl" label="URL" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="msg" label="备注" show-overflow-tooltip> </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button
                                v-if="userBtns.cockpit_taskexecution_update"
                                type="text"
                                size="small"
                                @click="editItem(row, '修改任务执行配置')"
                                >修改</el-button
                            >
                            <el-button type="text" size="small" @click="editItem(row, '查看任务执行配置')"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div
                class="table"
                style="height: calc(87% - 70px)"
                v-if="mainTab == '1' && subMenu.collaborative_office_configuration"
            >
                <el-table
                    key="2"
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(70% -10px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                >
                    <el-table-column prop="applyName" label="应用名称" />
                    <el-table-column prop="ssoUrl" label="单点登录链接" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="barsName" label="用户范围" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="applyType" label="应用类型" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="sort" label="排序" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="msg" label="备注" show-overflow-tooltip> </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button
                                v-if="userBtns.cockpit_collaborativeoffice_update"
                                type="text"
                                size="small"
                                @click="editCoordination(row, '修改协同办公配置')"
                                >修改</el-button
                            >
                            <el-popconfirm
                                v-if="userBtns.cockpit_collaborativeoffice_delete"
                                title="确定删除吗？"
                                @confirm="handleDel(row)"
                            >
                                <template #reference>
                                    <span slot="reference">
                                        <el-button type="danger" size="small" link>删除</el-button>
                                    </span>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                    v-model:currentPage="query.pageNum"
                    v-model:page-size="query.pageSize"
                    :page-sizes="[15, 30, 50, 100]"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="getList"
                    @current-change="getList"
                >
                </el-pagination>
            </div>
        </main>
        <!--        //任务执行-->
        <task-dialog
            v-if="indexEditDialog"
            :propsData="indexEditData"
            :title="title"
            @close="indexEditClose"
        ></task-dialog>
        <!--        协同办公-->
        <coordination-dialog
            v-if="coordinationEditDialog"
            :propsData="coordinationData"
            :title="coordinationTitle"
            @close="coordinationClose"
        ></coordination-dialog>
    </div>
</template>
<script setup>
import taskDialog from './taskDialog';
import coordinationDialog from './coordinationDialog';
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
// import {http} from '@/utils/request'
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import http from '../../utils/request';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
let headers = {
    Authorization: localStorage.token,
};
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    pageNum: 1,
    pageSize: 15,
});
let total = ref(0);
let mainTab = ref('0');
let barsId = ref([]);
let option_bar = ref([]);
let tableData = ref([]);
let indexEditDialog = ref(false);
let coordinationEditDialog = ref(false);
let indexEditData = ref({});
let coordinationData = ref({});
let title = ref('');
let coordinationTitle = ref('');
let option_type = ref([
    { value: '协同办公', label: '协同办公' },
    { value: '特色应用', label: '特色应用' },
]);
async function editItem(row = null, name) {
    const res = await axios({
        url: '/front/taskExecution/' + row.id,
        method: 'get',
    });
    if (res) {
        indexEditDialog.value = true;
        indexEditData.value = JSON.parse(JSON.stringify(res));
        title.value = name;
    }
}
async function addItem(row = null, name) {
    const res = await axios({
        url: '/front/taskExecution/' + row.id,
        method: 'get',
    });
    if (res) {
        indexEditDialog.value = true;
        indexEditData.value = JSON.parse(JSON.stringify(res));
        title.value = name;
    }
}
//新增协同办公btn
function hangleAdd() {
    coordinationEditDialog.value = true;
    coordinationData.value = {};
    coordinationTitle.value = '新增协同办公配置';
}
// 修改协同办公配置
function editCoordination(row = null, name) {
    http.get('/front/cooperativeOffice/' + row.id, {})
        .then((res) => {
            if (res.code == 200) {
                coordinationEditDialog.value = true;
                coordinationData.value = JSON.parse(JSON.stringify(res.data));
                coordinationTitle.value = name;
            }
        })
        .catch((err) => {
            console.log(res);
        });
}
// 查询条线列表
function getBarList(row = null, name) {
    http.get('/front/bars/list', {})
        .then((res) => {
            option_bar.value = res;
        })
        .catch((err) => {
            console.log(res);
        });
}

function indexEditClose() {
    indexEditDialog.value = false;
    getList();
}
function coordinationClose() {
    coordinationEditDialog.value = false;
    getList();
}

const mainTabClick = (vm) => {
    mainTab.value = vm.props.name;
    query.value = {
        pageNum: 1,
        pageSize: 15,
    };
    barsId.value = [];
    getList();
    router.replace({
        query: { tab: vm.props.name },
    });
};
//删除
function handleDel(row) {
    axios
        .delete('/front/cooperativeOffice/' + row.id, {})
        .then((res) => {
            if (res === 1) {
                ElMessage({
                    message: '删除成功',
                    type: 'success',
                });
                getList();
            }
        })
        .catch((err) => {
            console.log(res);
        });
}
async function getList() {
    let url = ['/front/taskExecution/list', '/front/cooperativeOffice/list'];
    http.get(url[mainTab.value], {
        params: query.value,
    })
        .then((res) => {
            if (res.code == 200) {
                if (res.rows) {
                    tableData.value = res.rows;
                    total.value = res.total;
                } else {
                    tableData.value = res.data.rows;
                    total.value = res.data.total;
                }
            }
        })
        .catch((err) => {
            console.log(res);
        });
}
function search() {
    if (barsId.value.length > 0) {
        query.value.barsId = barsId.value.toString();
    } else {
        delete query.value.barsId;
    }
    query.value.pageNum = 1;
    getList();
}
function resize() {
    barsId.value = [];
    query.value = {
        pageNum: 1,
        pageSize: 15,
    };
    getList();
}

// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
//获取subMenu权限
const subMenu = computed(() => {
    return store.state.user.sub_menu;
});
watch(() => {});
onMounted(() => {
    getList();
    getBarList();
    // mainTab.value = route.query.tab || '0';
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.problemFound {
    height: calc(100% - 90px);
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 40px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .tab-bar {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .subToolbar {
                text-align: left;
                margin-bottom: 16px;
                .el-button {
                    margin-right: 8px;
                }
            }
            .btns {
                text-align: right;
            }
        }

        .table {
            height: calc(100% - 70px);
            margin-top: 20px;
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                padding-top: 10px;
                text-align: right;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
    .status {
        display: inline-block;
        width: 68px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        &.status2 {
            background: rgba(100, 183, 255, 0.2);
            color: #1492ff;
        }
        &.status3 {
            background: rgba(68, 203, 87, 0.2);
            color: #44cd40;
        }
        &.status1 {
            background: rgba(255, 176, 81, 0.2);
            color: #ff8b00;
        }
        &.status4 {
            background: rgba(255, 127, 127, 0.2);
            color: #ff5c5c;
        }
        &.status0 {
            background: rgba(216, 216, 216, 0.2);
            color: #999999;
        }
        &.status5 {
            background: rgba(85, 230, 193, 0.2);
            color: rgb(85, 230, 193);
        }
        &.status6 {
            background: rgba(179, 55, 113, 0.2);
            color: rgb(179, 55, 113);
        }
        &.status7 {
            background: rgba(189, 197, 129, 0.2);
            color: rgb(189, 197, 129);
        }
    }
}
</style>
